<template>
    <div class="p-col-12 p-md-10 p-md-offset-1 p-align-center p-card p-shadow-3">
        <div class="p-grid p-align-start vertical-container" v-show="this.IsDataLoaded">
            <div class="p-col-4 p-grid p-mt-2">
                <h6><i class="p-ml-2 pi pi-user p-mr-2" />{{ $t('bets.ticketHeaderAccountInfo') }}</h6>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ $t('bets.channel') }}</div>
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ getTicketChannel() }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ $t('bets.placeDate') }}</div>
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ formatCSDateAndTime(ticketContent.ticketDate) }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ $t('bets.processDate') }}</div>
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ ticketContent.validateDate != null ? formatCSDateAndTime(ticketContent.validateDate) : $t('bets.pendingTicket') }}</div>
                </div>
            </div>
            <div class="p-col-4 p-grid p-mt-2">
                <h6><i class="p-ml-2 pi pi-wallet p-mr-2" />{{ $t('bets.ticketHeaderStakeInfo') }}</h6>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.playedAmount') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ formatCurrency(ticketContent.playedAmount) }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.stakeAmount') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ formatCurrency(ticketContent.stakeAmount) }}</div>
                </div>
            </div>
            <div class="p-col-4 p-grid p-mt-2">
                <h6><i class="p-ml-2 pi pi-dollar p-mr-2" />{{ $t('bets.ticketHeaderBetInfo') }}</h6>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('general.status') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">
                        <Button
                            v-if="ticketContent.StatusAvailable == false && ticketContent.Status != 2"
                            :label="$t('bets.status.' + getTicketStatusName(ticketContent.status)) + ' - ' + $t('bets.status.awaitsApproval')"
                            :class="'p-button-text  p-button-' + getTicketStatusColor(ticketContent.status) + ' p-pt-0 p-pb-0 p-ml-0 p-pl-0'"
                        />
                        <Button v-else :label="$t('bets.status.' + getTicketStatusName(ticketContent.status))" :class="'p-button-text  p-button-' + getTicketStatusColor(ticketContent.status) + ' p-pt-0 p-pb-0 p-ml-0 p-pl-0'" />
                    </div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0" v-show="ticketContent.status != 2">
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ $t('bets.winAmount') }}</div>
                    <div class="p-col-6 p-pt-0 p-pb-1">{{ notNullOrEmptyObject(ticketContent.winInfo) ? formatCurrency(ticketContent.winInfo.winAmount) : 0 }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0" v-show="ticketContent.status == 2">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.maxWinAmount') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ formatCurrency(ticketContent.maxWinAmount) }}</div>
                </div>
                <div class="p-col-12 p-grid p-mt-0 p-mb-0 p-pt-0 p-pb-0" v-show="ticketContent.status == 2">
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ $t('bets.minWinAmount') }}</div>
                    <div class="p-col-6 p-mt-0 p-mb-0 p-pt-0 p-pb-1">{{ formatCurrency(ticketContent.minWinAmount) }}</div>
                </div>
            </div>
            <hr />
            <div class="p-col-12">
                <Panel>
                    <table>
                        <thead>
                            <tr class="p-text-bold">
                                <td>{{ $t('general.no') }}</td>
                                <td class="p-col-3 p-pl-0">
                                    {{ $t('lotto.numbers') }}
                                </td>
                                <td class="p-col-1 p-pl-0">
                                    {{ $t('lotto.drawType') }}
                                </td>
                                <td class="p-col-2 p-pl-0">{{ $t('lotto.lotteryName') }}</td>
                                <td>{{ $t('bets.system') }}</td>
                                <td>
                                    {{ $t('bets.noOfComb') }}
                                </td>
                                <td>{{ $t('bets.lineStakeAmount') }}</td>
                                <td>{{ $t('bets.minWinAmount') }}</td>
                                <td>{{ $t('bets.maxWinAmount') }}</td>
                                <td>{{ $t('bets.winAmount') }}</td>

                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(ticketLine, index) in ticketContent.ticketLines" :key="ticketContent.ticketSerial + 'line_' + index">
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ index + 1 }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ ticketLine.numbers }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ ticketLine.drawType }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ ticketLine.betName }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ this.notNullOrEmptyObject(ticketLine.system) ? ticketLine.system : 'none' }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ ticketLine.noOfCombinations }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ formatCurrency(ticketLine.lineStakeAmount) }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ formatCurrency(ticketLine.minWinAmount) }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ formatCurrency(ticketLine.maxWinAmount) }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">{{ formatCurrency(ticketLine.winAmount) }}</td>
                                <td class="p-mt-0 p-mb-0 p-pt-0">
                                    <Button :label="$t('bets.status.' + getTicketStatusName(ticketLine.status))" :class="'p-button-text  p-button-' + getTicketStatusColor(ticketLine.status) + ' p-pt-0 p-pb-0'" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Panel>
            </div>
        </div>
        <div class="p-col-12 p-text-center" v-show="!this.IsDataLoaded && !this.showNoTicket">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </div>
    </div>
</template>
<script>
import { BoTicketsApi } from '../../../service/BoTicketsApi';

export default {
    name: 'lottoTicketDetails',
    emits: ['checkPayment'],
    props: {
        ticketSerial: {
            type: String,
        },
        forPay: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            BoTicketsApi: new BoTicketsApi(),
            ticketContent: [],
            IsDataLoaded: false,
            showNoTicket: false,
        };
    },
    beforeMount() {
        this.getTicketContent();
    },
    methods: {
        getTicketContent() {
            this.BoTicketsApi.getTicketContent(this.ticketSerial)
                .then((response) => {
                    this.ticketContent = response.data;
                    this.IsDataLoaded = true;
                    if (this.forPay) {
                        this.$emit('checkPayment', this.ticketContent.status);
                    }
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showNoTicket = true;
                    this.IsDataLoaded = false;
                });
        },
        getTicketChannel() {
            var response = '';
            if (this.notNullOrEmptyField(this.ticketContent.cashierId)) {
                response = this.$t('bets.channelCashier');
            } else {
                response = this.$t('bets.channelTerminal');
            }
            return response;
        },
    },
};
</script>

